<template>
  <div class="db" :class="{ show: isActive }">
    <div class="header-wrap">
      <Header />
      <!-- <SubHeader :title="$t('movies')" /> -->
    </div>
    <div class="mainView mainView-aligned">
      <div class="volatile-billboard-animations-container" v-if="billboardMovies">
        <Billboard :click="showInfo" :item="billboardMovies" />
      </div>
      <VideoTeleport />
      <div ref="scrollComponentAllMovies" class="slider-container" v-if="!searchMovies">
        <div v-for="category in categories" :key="category.id">
          <Slider class="category-slider" :category="category" :cards="itemsArr" :isInfos="true" />
          <!-- </v-lazy> -->
        </div>
      </div>
      <div ref="scrollComponentAllMovies" class="slider-container" v-if="searchMovies">
        <div class="search-title">
          {{ searchText }}
        </div>
        <div class="lists-content justify-content">
          <div v-for="item in searchMovies" :key="item.id">
            <SliderItem :item="item" :brandImage="brandImage" />
          </div>
        </div>
      </div>
    </div>
    <Footer class="member-footer" />
    <Loader class="loader" v-if="lazyLoading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "@/components/Header/Header";
import Loader from "@/components/Loader/Loader";
// import SubHeader from "@/components/Header/SubHeader";
import SliderItem from "@/components/Slider/SliderItem.vue";
import Billboard from "@/components/Billboard/Billboard";
import Slider from "@/components/Slider/Slider";
import Footer from "@/components/Footer/Footer";
import VideoTeleport from "@/components/Slider/VideoTeleport";
import { debounce } from "lodash";

export default {
  name: "Movies",
  components: {
    VideoTeleport,
    Header,
    SliderItem,
    // SubHeader,
    Billboard,
    Loader,
    Slider,
    Footer,
  },
  computed: {
    ...mapState({
      isActive: (state) => state.infoMovie.isActive,
      categories: (state) => {
        return state.movies.categories;
      },
      searchMovies: (state) => {
        return state.movies.searchMovies;
      },
      searchText: (state) => {
        return state.movies.searchText;
      },
      brandImage: (state) => `/img/${state.config?.brandName}-logo.png`,

      isLoadingCategory: (state) => state.movies.isLoading,
      itemsArr: (state) => state.movies.movies
    }),
    recentAddedCategory() {
      const RECENT_ADDED_CATEGORY_ID = 2;
      return this.$store.state.movies.categories?.find(
        category => category.id === RECENT_ADDED_CATEGORY_ID
      );
    },

    billboardMovies() {
      const moviesList = this.recentAddedCategory?.infos;
      if (!moviesList?.length) {
        return null;
      }

      const randomIndex = Math.floor(Math.random() * moviesList.length);
      return moviesList[randomIndex];
    },
  },
  data() {
    return {
      offset: 0,
      lazyLoading: false,
      randomMovie: null,
      scrollAtTop: true,
    };
  },
  methods: {
    showInfo() {
      this.$store.commit("handleGetInfoMovie", this.billboardMovies);
      this.$store.commit("handleShowInfoMovie", true);
    },
    loadCategories(offset) {
      this.$store.commit("finishedCategoryLoader", false);
      this.$store.dispatch("initMoviesLoad", {
        offset: offset,
        size: 3,
      });
    },
    async handleScroll() {
      this.scrollAtTop = false;
      let element = this.$refs.scrollComponentAllMovies;

      if (!element) return;

      if (element.getBoundingClientRect().bottom < window.innerHeight && this.lazyLoading === false) {
        this.lazyLoading = true;

        this.offset = this.offset + 3;
        await this.$store.dispatch("loadMoreCategories", {
          offset: this.offset,
          size: 3,
        });
        this.lazyLoading = false;
      }
    },
  },
  created() {
    this.debouncedScroll = debounce(this.handleScroll, 200);
  },
  async mounted() {
    try {
      await this.loadCategories(this.offset);
    } catch (error) {
      console.error('Failed to load categories:', error);
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.loader {
  position: sticky;
  bottom: 150px;
}

.db {
  &.show {
    height: 100.1vh;
    overflow: hidden;
  }
}
</style>
